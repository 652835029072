<template>
  <div class="team">
    <v-container v-if="schedule_data != null" class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text"> الجدول </h1>
        <h3 class="text-center mb-3 subtitle-4 black--text"> {{ $route.params.class_school_leader }}-{{
            $route.params.classes_name
        }} </h3>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <table style="width: 100%; border: 1px solid black; text-align: center">
              <thead>
                <th>*</th>
                <th>السبت</th>
                <th>الاحد</th>
                <th>الاثنين</th>
                <th>الثلاثاء</th>
                <th>الاربعاء</th>
                <th>الخميس</th>
              </thead>
              <tbody>
                <!-- classOne -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الاول</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_1" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_1" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classTwo -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الثاني</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_2" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_2" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classThree -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الثالث</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_3" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_3" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classFour -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الرابع</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_4" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_4" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classFive -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الخامس</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_5" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_5" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classSix -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس السادس</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_6" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_6" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classSeven -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس السابع</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_7" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_7" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
                <!-- classEight -->
                <tr>
                  <td class="details">
                    <div class="test">
                      <span style="display: inline-block">الدرس الثامن</span>
                      <span style="display: inline-block">الاستاذ</span>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[0].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[1].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[2].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[3].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[4].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                  <td>
                    <div class="vertical">
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_lecture_8" hide-details clearable
                        :items="testItem" item-text="subject_name" item-value="subject_name" :loading="subjectLoading"
                        dense filled rounded></v-autocomplete>
                      <v-autocomplete v-model="schedule_data[5].schedule_weekly_teacher_8" hide-details clearable
                        :items="teacherData" item-text="account_name" dense filled rounded></v-autocomplete>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <!-- editBtn -->
        <v-row class="ma-10">
          <v-btn color="primary" :loading="changeScheduleLoading" @click="changeSchedule">
            <span class="white--text"> تعديل </span>
            <v-icon right> fa-file </v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/api/api'
export default {
  data() {
    return {
      ClassSchool_leader: '',

      successAddDailog: false,

      changeScheduleLoading: false,

      overlay: false,

      schedule_data: [
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "السبت",
          schedule_weekly_order: 0,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        },
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "الاحد",
          schedule_weekly_order: 1,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        },
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "الاثنين",
          schedule_weekly_order: 2,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        },
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "الثلاثاء",
          schedule_weekly_order: 3,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        },
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "الاربعاء",
          schedule_weekly_order: 4,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        },
        {
          created_at: null,
          _id: null,
          schedule_weekly_day: "الخميس",
          schedule_weekly_order: 5,
          schedule_weekly_lecture_1: null,
          schedule_weekly_teacher_1: null,
          schedule_weekly_lecture_2: null,
          schedule_weekly_teacher_2: null,
          schedule_weekly_lecture_3: null,
          schedule_weekly_teacher_3: null,
          schedule_weekly_lecture_4: null,
          schedule_weekly_teacher_4: null,
          schedule_weekly_lecture_5: null,
          schedule_weekly_teacher_5: null,
          schedule_weekly_lecture_6: null,
          schedule_weekly_teacher_6: null,
          schedule_weekly_lecture_7: null,
          schedule_weekly_teacher_7: null,
          schedule_weekly_lecture_8: null,
          schedule_weekly_teacher_8: null,
          schedule_weekly_class_school: this.$route.params.class_school_id,
          schedule_weekly_school: null,
          schedule_study_year: JSON.parse(localStorage.getItem("study_year"))
        }

      ],

      subjectLoading: false,

      testItem: [],

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      teacherData: [],

      schedule_Subject_select: [],
    }
  },
  created() {
    // this.getClassSchool_leader();
    // this.getScheduleSubject();
    this.getScheduleData()
    this.getTeacherDataAxios()
    this.getSubjectDataAxios()
  },
  methods: {
    // getClassSchool_leader() {
    //   let headers = { authorization: this.$store.state.accessToken };
    //   this.$http
    //     .get(`examTable/${this.$route.params.id}`, { headers })
    //     .then((Response) => {
    //       this.ClassSchool_leader = "";
    //       if (Response.data.error == false) {
    //         var Results = Response.data.results;
    //         const found = Results.find(
    //           (element) =>
    //             element.class_school_forclass == this.$route.params.class_id
    //         );
    //         if (found) {
    //           this.ClassSchool_leader = found;
    //         } else {
    //           this.$router.replace("/schedule");
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    async getScheduleData() {
      this.overlay = true

      const study_year = JSON.parse(localStorage.getItem('study_year'))
      const response = await api.getSchedule(this.$route.params.class_school_id, study_year)

      if (response.status === 401) {
        this.overlay = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.overlay = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.overlay = false
        if (response.data.results.length != 0) {
          this.schedule_data = response.data.results
        }
      }
    },

    async getSubjectDataAxios() {
      this.subjectLoading = true

      const response = await api.getSubject()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.subjectLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.subjectLoading = false
        this.testItem = response.data.results
      }
    },

    async getTeacherDataAxios() {

      const response = await api.getAllTeachers()
      console.log('response', response);
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.teacherData = response.data.results
      }
    },

    async changeSchedule() {
      this.changeScheduleLoading = true

      // const study_year = JSON.parse(localStorage.getItem('study_year'))


      const response = await api.addSchedule(this.schedule_data)
      if (response.status === 401) {
        this.changeScheduleLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.changeScheduleLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.changeScheduleLoading = false
        this.showDialogfunction(response.data.results, 'primary')
      }

    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid cyan;
  padding: 4px 6px;
}

th {
  padding: 15px 0;
  background-color: rgb(228, 240, 245);
}

.test {
  display: grid;
  gap: 26px;
}

.vertical {
  display: grid;
  gap: 24px;
}

.details {
  background-color: rgb(228, 240, 245);
}

/* .select.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
  padding-right: 4px
} */
</style>
